import axios from 'axios';
// hooks
import { useState } from 'react';

// styles
import 'mapbox-gl/dist/mapbox-gl.css';
import "../pages/pagesStyle/registerPageStyles.css";
// components
import Map, { NavigationControl, Marker, GeolocateControl } from 'react-map-gl';
import { Button, Form, Input, message, Checkbox } from 'antd';

// assets
import pin from '../assets/pin.png';
// credentials
import { MAPBOX_PUBLIC_TOKEN } from '../config/credentials';
import { API_URL } from '../config/credentials';

function BussinessUserForm() {
  const [markerPosition, setMarkerPosition] = useState({ latitude: 40.4, longitude: -3.9 });
  
  const onFinish = async (values) => {
    console.log("las wevadas esas",markerPosition);
    try {
      const response = await axios.post(`${API_URL}/restaurants`, {
        company_name: values.companyName,
        res_password: values.password,
        legal_form: values.legal_form,
        commercial_registry: values.commercial_registry,
        phone: values.phone,
        email: values.email,
        res_latitude: markerPosition.latitude,
        res_longitude: markerPosition.longitude,
        res_address: values.res_address,
        res_type: values.res_type
      });
  
      if (response.status === 201) {
        console.log('Restaurant added successfully:', response.data);
        message.success('Cuenta creada de manera exitosa!');
      }
    } catch (error) {
      console.error('Error adding restaurant:', error);
      message.error('Error en la creación de cuenta.');
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleMapClick = (event) => {
    const { lngLat } = event;
    setMarkerPosition({ longitude: lngLat.lng, latitude: lngLat.lat });
    console.log(markerPosition);
  };

  const handleMarkerDrag = (event) => {
    const { lngLat } = event;
    setMarkerPosition({ longitude: lngLat.lng, latitude: lngLat.lat });
    console.log(markerPosition);
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={(values) => onFinish({ ...values })}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Nombre de la compañía"
          name="companyName"
          rules={[{ required: true, message: 'Por favor ingresa el nombre del restaurante' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Por favor ingresa una contraseña' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="CIF"
          name="legal_form"
          rules={[{ required: true, message: 'Por favor ingresa tu CIF' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Registro comercial"
          name="commercial_registry"
          rules={[{ required: true, message: 'Por favor ingresa tu registro comercial' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Teléfono"
          name="phone"
          rules={[{ required: true, message: 'Por favor ingresa tu número de teléfono' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Correo electrónico"
          name="email"
          rules={[{ required: true, message: 'Por favor ingresa el correo' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="res_latitude" noStyle>
          <Input type="hidden" value={markerPosition.latitude} />
        </Form.Item>
        <Form.Item name="res_longitude" noStyle>
          <Input type="hidden" value={markerPosition.longitude} />
        </Form.Item>

        <Form.Item
          label="Dirección del restaurante"
          name="res_address"
          rules={[{ required: true, message: 'Favor de ingresar la dirección' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tipo de restaurante"
          name="res_type"
          rules={[{ required: true, message: 'Por favor ingresa el tipo de restaurante' }]}
        >
          <Input />
        </Form.Item>

        <div style={{ height: '280px', width: '100%' }}>
          <Map
            mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
            initialViewState={{
              longitude: -3.9,
              latitude: 40.4,
              zoom: 9,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={handleMapClick}
            style={{ width: '100%', height: '100%' }}
          >
            <Marker
              longitude={markerPosition.longitude}
              latitude={markerPosition.latitude}
              anchor="bottom"
              draggable
              onDrag={handleMarkerDrag}
            >
              <img src={pin} alt="Marker" width={30} height={30} />
            </Marker>
            <GeolocateControl />
            <NavigationControl showCompass showZoom visualizePitch />
          </Map>
        </div>

        {/* Agregamos el Checkbox de Términos y Condiciones */}
        <Form.Item
          name="terms"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Debes aceptar los términos y condiciones')),
            },
          ]}
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>
            He leído y acepto los <a href="CONDICIONES_Y_TERMINOS_GENERALES_DE_USO_PARA_EMPRESAS_Y_PROFESIONALES.pdf" target="_blank">términos y condiciones</a>.
          </Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default BussinessUserForm;